<style>
/************************************************************
** 请将全局样式拷贝到项目的全局 CSS 文件或者当前页面的顶部 **
** 否则页面将无法正常显示                                  **
************************************************************/

html {
  font-size: 16px;
}
#app {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Microsoft Yahei", sans-serif;
  background-color: #f5f8ff;
}

body * {
  box-sizing: border-box;
  flex-shrink: 0;
}
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}
.xyb-flex-row {
  display: flex;
  flex-direction: row;
}

.xyb-flex-col {
  display: flex;
  flex-direction: column;
}

.xyb-justify-start {
  justify-content: flex-start;
}

.xyb-items-center {
  align-items: center;
}

.xyb-self-start {
  align-self: flex-start;
}

.xyb-self-center {
  align-self: center;
}
</style>
<template>
  <div class="flex-col page">
    <div class="flex-col imageBg space-y-40">
      <div class="flex-col group_2 flex-row">
        <div class="flex-row space-x-4">
          <img
            class="proImge"
            :src="productLogo"
            :onerror="default_cattle"
            alt=""
          />
          <span class="proName">{{ productName }}</span>

          <div class="timebg"></div>

          <span class="timeTitle">倒计时：</span>
          <van-count-down :time="time">
            <template #default="timeData">
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">时</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">分</span>
              <span class="block">{{ timeData.seconds }}</span>
              <span class="colon">秒</span>
            </template>
          </van-count-down>
        </div>

        <div class="auditView flex-row justify-center">
          <img
            class="image_2"
            src="../../assets/images/model8/model8_icon.png"
          />
          <span class="model8_font_1 auditTitle">恭喜您，已通过初审! </span>
        </div>
        <div class="auditSubtitle">{{ titleSring }}</div>
        <div class="flex-col">
          <div class="flex-col section">
            <div class="flex-row justify-between group_3">
              <div class="flex-row space-x-8">
                <span class="text_3">¥</span>
                <van-field
                  type="digit"
                  placeholder="预估额度55000"
                  class="inp-elem"
                  maxLength="6"
                  v-model="borrowCount"
                  @change="changeBorrowCount"
                  @clear="clearBorrowCount"
                  clearable
                />
              </div>
            </div>
            <div class="flex-row justify-center group_4">
              <div class="flex-col justify-start text-wrapper">
                <span class="text_5">限时优惠</span>
              </div>
              <div class="flex-row justify-center group_5">
                <span class="font_3 text_6">日利率</span>
                <span class="text_8">0.025%</span>
              </div>
              <div class="flex-col justify-start group_6">
                <span class="font_3 text_7">0.035%</span>
                <img
                  class="image_6"
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134755636471950.png"
                />
              </div>
              <span class="font_3 text_9">(一千元用一天只需0.25元)</span>
            </div>
          </div>
          <div class="flex-col group_7 space-y-18">
            <div class="flex-row justify-evenly group_8">
              <img
                class="image_7"
                src="../../assets//images/model8/model8_left.png"
              />
              <span class="font_3 text_10"
                >次日起可还款，免手续费，免剩余天数利息</span
              >
              <img
                class="image_7"
                src="../../assets/images/model8/model8_right.png"
              />
            </div>
            <div class="flex-col space-y-20">
              <div class="flex-col space-y-10">
                <div
                  class="flex-row justify-between list-item"
                  :key="i"
                  v-for="(item, i) in list"
                  @click="clickItemAction(item, i)"
                >
                  <span class="font_1 text_11">{{ item.title }}</span>
                  <div class="flex-col items-end space-y-10">
                    <span class="font_4">{{
                      item.monthlyRepayment +
                      Number(item.monthlyRepaymentAcount).toFixed(2)
                    }}</span>
                    <span class="font_3 text_12">{{
                      item.interest + Number(item.interestAcount).toFixed(2)
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="flex-row justify-between bank_bg group_9">
                <span class="font_4 bankTitle">收/还款账户</span>
                <div class="flex-row">
                  <img
                    class="bank_img"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/fea5200410ddfa7908d6e6d7935b8003.png"
                  />
                  <img
                    class="bank_img"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134754010746189.png"
                  />
                  <img
                    class="bank_img"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134754394625130.png"
                  />
                  <img
                    class="bank_img"
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16703134755124262930.png"
                  />
                  <span class="bank_arrow">…</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        class="flex-col justify-start items-center button"
        @click="handleSubmit"
      >
        <span class="font_2 text_18">{{ btnTxt }}</span>
      </button>

      <span class="downloadText" v-on:click="handleDownload"
        >下载APP匹配更多资金</span
      >
    </div>
    <!-- 提示浏览器打开遮罩层 -->
    <SharePop :show="showPop" @change="handlePopChange" />
    <van-popup
      v-model="isShowPop"
      position="bottom"
      closeable
      @close="handelClosePop"
      v-if="this.popData.productName"
    >
      <div class="pop-countent">
        <div class="title">
          <span class="span-icon"></span>
          <span class="title-text"
            >恭喜您已通过{{ popData.productName }}初审</span
          >
        </div>
        <div class="countent">
          <div class="num">{{ popData.loanAmount }}元</div>
          <div class="tip">额度由{{ popData.productName }}提供</div>
        </div>
        <div class="agree-txt">
          <van-checkbox
            v-model="checked"
            class="check-box"
            shape="square"
          ></van-checkbox>
          <span class="txt"
            >我已阅读并同意<i @click="handleProtocol(9)"
              >《个人信息查询授权书》</i
            ></span
          >
        </div>
        <div class="btn-pop" @click="handlePopBtn">去提现</div>
      </div>
    </van-popup>

    <!--    惠逸花弹窗-->
    <div class="xyb-flex-col xyb-justify-start box_xyb" v-show="isShowCYTPop">
      <div class="xyb-flex-col xyb-justify-start group_xyb_1">
        <div class="xyb-flex-col section_xyb_3 space-y-18">
          <div class="xyb-flex-col xyb-items-center space-y-12">
            <img
              class="image_xyb"
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16788622859890778724.png"
            />
            <span class="font_xyb_1">{{ this.apiName }}</span>
          </div>
          <div class="xyb-flex-col space-y-22">
            <span class="xyb-self-center font_xyb_1"
              >已有机构为您优先服务！</span
            >
            <div class="xyb-flex-col">
              <!--              <van-list-->
              <!--                v-model="loading"-->
              <!--                :finished="finished"-->
              <!--                finished-text=""-->
              <!--                @load="onLoad"-->
              <!--              >-->
              <template>
                <div
                  v-for="(item, index) in HYHList"
                  :key="index"
                  class="product-item"
                >
                  <div class="xyb-flex-col xyb-justify-start section_xyb_4">
                    <div class="u-flex u-row-between u-col-center item_bg">
                      <div class="u-flex u-col-center">
                        <span class="item_title">{{ item.productName }}</span>
                      </div>
                    </div>
                    <div class="xyb-flex-row space-x-18">
                      <div
                        class="xyb-flex-col xyb-items-center equal-division-item space-y-6"
                      >
                        <span class="font_xyb_2 text_xyb">{{
                          item.price
                        }}</span>
                        <span class="font_xyb_3">最高额度(元)</span>
                      </div>
                      <div
                        class="xyb-flex-col xyb-items-center equal-division-item space-y-12"
                      >
                        <span class="font_xyb_2">{{ item.rate }}%</span>
                        <span class="font_xyb_4 text_xyb_2">平均月利率</span>
                      </div>
                      <div
                        class="xyb-flex-col xyb-items-center equal-division-item space-y-6"
                      >
                        <span class="font_xyb_2">{{ item.month }}</span>
                        <span class="font_xyb_3">最高可借(期)</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <!--              </van-list>-->

              <div class="xyb-flex-row group_xyb space-x-8">
                <van-checkbox
                  shape="square"
                  icon-size="17px"
                  v-model="cytChecked"
                >
                </van-checkbox>
                <div class="text-wrapper_10">
                  <span class="font_xyb_4 text_xyb_3">我已阅读并同意</span>
                  <span
                    class="font_xyb_4 text_xyb_4"
                    @click.stop="handleProtocol(12)"
                    >《用户个人信息共享授权协议》</span
                  >
                </div>
              </div>
              <div
                class="xyb-flex-col xyb-items-center button space-y-8"
                @click="clickCYTApplyBtn"
              >
                <span class="font_xyb_2 text_xyb_5">立即申请</span>
              </div>
              <div
                class="xyb-self-center font_xyb_1 text_xyb_7"
                @click="clickCYTCancelBtn"
              >
                取消申请
              </div>
              <div class="xyb-flex-col group_xyb_3">
                <span class="font_xyb_5 text_xyb_8">
                  本次借款服务由惠逸花平台提供
                  <br />
                </span>
                <span class="font_xyb_5 text_xyb_9"
                  >融e钱包平台仅提供信息展示,不参与任何放款行为</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    协议弹窗-->
    <div class="po_section_1 flex-col box_xyb" v-show="isShowProtocol">
      <div class="po_block_1 flex-col">
        <div class="po_box_1 flex-col">
          <img
            class="po_thumbnail_1"
            referrerpolicy="no-referrer"
            src="../../assets/images/model8/model8_po_close.png"
            v-on:click="handelCloseProtocol"
          />
          <div class="po_text-group_1 flex-col justify-between">
            <span class="po_text_1">请阅读以下协议</span>
          </div>
          <div class="flex-col po_content justify-between">
            <span class="contentText" v-html="apiProduct.content"> </span>
          </div>
          <button class="po_button_1 flex-col" @click="handelAgreeProtocol">
            <span class="po_text_2">我已阅读并同意授权</span>
          </button>
        </div>
      </div>
    </div>
    <!-- 下载跳转提示 -->
    <div class="van-overlay" v-show="showDownloadPop">
      <img
        class="icon-download"
        src="../../../public/img/register/download_bg.png"
      />
    </div>
  </div>
</template>

<script>
import * as API_Common from "@/api/common";
import "@/css/stagingAssessment.scss";
import Vue from "vue";
import { CountDown } from "vant";
Vue.use(CountDown);

window.onpageshow = function (event) {
  if (event.persisted) {
    window.location.reload();
  }
};
export default {
  components: {},
  data() {
    return {
      channel: this.$route.query.source,
      default_cattle:
        'this.src="' + require("../../../public/aed_logo.png") + '"', //加载图片报错时处理方法
      model: sessionStorage.getItem("model"),
      //借款金额
      borrowCount: undefined,
      //年利率7.2
      rate: 0.091,
      //月利率
      monthRate: 12,
      defaultInspectionRef: undefined,
      params: {
        pageNum: 1,
        pageSize: 5,
      },
      titleSring: undefined,
      isFinsh: true,
      isShowPop:
        !window.localStorage.getItem("closePop") &&
        window.localStorage.getItem("backFlag"),
      showDownloadPop: false,
      apiName: "", //腰部api产品名称 惠逸花或者企惠通
      checked: false,
      cytChecked: false,
      isShowCYTPop: false,
      isShowProtocol: false,
      isClickProtocol: false,
      downAppUrl: "",
      showPop: false,
      productName: "",
      productLogo: "",
      popData: {
        isShieldCity: false,
      },
      apiProduct: {
        imgUrl: "",
        thirdPartyId: "",
        productId: "",
        companyName: "",
        name: "",
        userId: "",
        content: "",
        redirectUrl: "",
        grantValue: "",
        grantApiLists: [],
      },
      recommendProduct: {},
      btnTxt: "立即下载",
      time: 80000000000,
      list: [
        {
          id: 0,
          title: "分12月还",
          select: true,
          monthlyRepayment: "首期预估还 ¥",
          monthlyRepaymentAcount: "5000.42",
          interest: "借满12个月,总利息:¥",
          interestAcount: "5005.00",
          selectImg: "../assets/images/stagingAssessment/img_select.png",
        },
        {
          id: 1,
          title: "分6月还",
          select: false,
          monthlyRepayment: "首期预估还 ¥",
          monthlyRepaymentAcount: "9583.75",
          interest: "借满6个月,总利息:¥",
          interestAcount: "2502.50",
          selectImg: "../assets/images/stagingAssessment/img_unselect.png",
        },
        {
          id: 2,
          title: "分3月还",
          select: false,
          monthlyRepayment: "首期预估还 ¥",
          monthlyRepaymentAcount: "18750.42",
          interest: "借满3个月,总利息:¥",
          interestAcount: "1251.25",
          selectImg: "../assets/images/stagingAssessment/img_unselect.png",
        },
      ],
      HYHList: [],
      loading: false,
      finished: false,
      isReload: false,
    };
  },
  created() {
    this.getDownloadUrl();
    this.getIsShieldCity();
    this.handleUserResidenceTime();
    if (window.localStorage.getItem("cytClosePop") == null) {
      this.getAPIProduct();
    }
    if (
      window.localStorage.getItem("closePop") == null &&
      window.localStorage.getItem("backFlag") &&
      this.popData.productId
    ) {
      this.isShowPop = true;
    }
  },
  methods: {
    //全部借出
    clickBorrowAllBtnDidAction() {
      this.borrowCount = 200000;
      this.changeBorrowCount();
    },
    // 改变借款金额
    changeBorrowCount() {
      if (this.borrowCount < 10000) {
        this.borrowCount = 10000;
      }
      if (this.borrowCount > 200000) {
        this.borrowCount = 200000;
      }
      this.monthRate = this.rate / 12;
      this.list[0].monthlyRepaymentAcount =
        (this.borrowCount * this.rate + parseInt(this.borrowCount)) / 12;
      this.list[1].monthlyRepaymentAcount =
        (parseInt(this.borrowCount) +
          parseInt(this.borrowCount) * this.monthRate * 6) /
        6;

      this.list[2].monthlyRepaymentAcount =
        (parseInt(this.borrowCount) +
          parseInt(this.borrowCount) * this.monthRate * 3) /
        3;
      this.list[0].interestAcount = this.borrowCount * this.rate;
      this.list[1].interestAcount = this.borrowCount * this.monthRate * 6;
      this.list[2].interestAcount = this.borrowCount * this.monthRate * 3;
    },
    //清空借款金额
    clearBorrowCount() {
      this.list[0].monthlyRepaymentAcount = 0;
      this.list[1].monthlyRepaymentAcount = 0;
      this.list[2].monthlyRepaymentAcount = 0;
      this.list[0].interestAcount = 0;
      this.list[1].interestAcount = 0;
      this.list[2].interestAcount = 0;
    },
    clickWithdrawalDidAction() {},
    //选中列表item
    clickItemAction(item, index) {
      this.list.forEach((it) => {
        it.select = it.id == index;
      });
    },
    // 关闭弹窗 如果点击关闭按钮之后，则以后不再弹窗
    handelClosePop() {
      this.isShowPop = false;
      window.localStorage.setItem("closePop", "ture");
      window.localStorage.removeItem("backFlag");
    },
    // 关闭协议弹窗
    handelCloseProtocol() {
      this.isShowProtocol = false;
    },
    //同意协议
    handelAgreeProtocol() {
      window.localStorage.setItem("isClickProtocol", "ture");
      this.pushPopProduct();
    },
    //点击弹窗产品去申请
    handlePopBtn() {
      if (!this.checked) {
        this.$toast("请勾选同意个人信息查询授权书");
        return;
      }
      API_Common.buried({
        productId: this.popData.productId,
        showPage: 5,
      }).then((res) => {
        console.log(res);
        if (200 === res.code) {
          setTimeout(() => {
            window.localStorage.setItem("closePop", "ture");
            window.localStorage.removeItem("backFlag");
            window.location.href = this.popData.linkUrl;
          }, 500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    //API机构和授权书
    getAPIProduct() {
      var that = this;
      API_Common.getUser2WhereApi().then((res) => {
        if (res.code == 200) {
          that.apiProduct = res.data;

          if (this.apiProduct.grantApiLists) {
            this.HYHList = JSON.parse(that.apiProduct.grantApiLists);
            this.isReload = true;
            this.isShowCYTPop = true;
            API_Common.delayModel9SUpdate(
              this.channel,
              0,
              0,
              8,
              this.apiProduct.thirdPartyId
            );
          }
        }
      });
    },
    //点击惠逸花立即申请
    clickCYTApplyBtn() {
      if (!this.cytChecked) {
        this.$toast("请勾选同意个人信息查询授权书");
        return;
      }
      this.pushHYHLink();
    },

    pushHYHLink() {
      let data;
      var array = [];
      for (let i = 0; i < this.HYHList.length; i++) {
        let model = this.HYHList[i];
        let dic = {
          productId: model.productId,
        };
        array.push(dic);
        data = {
          orderId: this.apiProduct.orderId,
          products: array,
        };
      }

      API_Common.getHYHProductData(data).then((res) => {
        if (0 == res.code) {
          this.$toast("申请成功");
          this.isShowCYTPop = false;
          window.localStorage.setItem("cytClosePop", "ture");
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    //惠逸花取消申请
    clickCYTCancelBtn() {
      this.isShowCYTPop = false;
      window.localStorage.setItem("cytClosePop", "ture");
    },
    //点击底部按钮
    handleSubmit() {
      //不屏蔽城市情况下  跳转推荐产品 返回后弹出惠逸花弹窗 点击关闭或者跳转回落地页之后有产品弹窗情况下弹出产品
      let isWeixin = this.isWeixin();
      if (isWeixin && this.channel != "wxgzh" && this.channel != "wxgzh1") {
        this.showPop = true;
        return;
      }
      if (this.popData.isShieldCity || !this.recommendProduct.id) {
        if (isWeixin && this.channel == "wxgzh" && this.channel != "wxgzh1") {
          this.showPop = true;
        } else {
          this.handleDownload();
        }
        return;
      }
      this.pushPopProduct();
    },

    //跳转推荐产品
    pushPopProduct() {
      API_Common.buried({
        productId: this.recommendProduct.id,
        showPage: 5,
      }).then((res) => {
        if (200 === res.code) {
          setTimeout(() => {
            window.localStorage.setItem("backFlag", "true");
            window.localStorage.removeItem("closePop");
            window.location.href = this.recommendProduct.linkUrl;
          }, 500);
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    getDownloadUrl() {
      API_Common.getDownloadUrl(this.channel).then((res) => {
        if (res.code === 200) {
          this.downAppUrl = res.data.url;
        }
      });
    },

    // 根据渠道code读取产品推荐信息 将权重最大的进行显示
    getChannleProductList() {
      API_Common.getChannleProductList(this.channel).then((res) => {
        if (res.code === 200) {
          let dataArr = res.data;
          if (dataArr.length > 0) {
            let maxItem = dataArr.reduce(function (prev, current) {
              return prev.sort > current.sort ? prev : current;
            });
            this.recommendProduct = maxItem;
          }
        }
      });
    },
    //获取是否屏蔽城市
    getIsShieldCity() {
      API_Common.getIsShieldCity(this.channel).then((res) => {
        if (res.code === 200) {
          if (res.data == false) {
            //当前城市未屏蔽
            this.getChannleProductList();
          }
          this.getPopProduct();
        }
      });
    },

    // 根据渠道code和进行到哪一步查询配置H5弹窗产品权重最大的值
    getPopProduct() {
      API_Common.getPopItem(this.channel).then((res) => {
        if (res.code === 200) {
          this.popData = res.data;
          if (res.data.isShieldCity || !this.recommendProduct.id) {
            this.btnTxt = "立即下载";
            this.productName = this.model === "model9" ? "安e贷" : "融e钱包";
            this.titleSring = "";
            this.productLogo =
              this.model === "model9"
                ? require("../../../public/aed_logo.png")
                : require("../../assets/images/model8/modelLogo.png");
          } else {
            this.btnTxt = "立即提现";
            this.productLogo = this.recommendProduct.imgUrl;
            this.titleSring = `您的借款将由合作方${this.recommendProduct.name}提供。`;
            this.productName = this.recommendProduct.name;
          }
        }
      });
    },

    handleProtocol(pageType) {
      if (pageType == 12) {
        let userId = window.localStorage.getItem("userId");
        window.location.href =
          "/protocol.html?pageType=" + pageType + "&userId=" + userId;
      } else {
        window.location.href = "/protocol.html?pageType=" + pageType;
      }
    },

    handlePopChange(show) {
      this.showPop = show;
    },
    handleDownload() {
      var that = this;
      if (that.model != "model9") {
        that.showDownloadPop = true;
        setTimeout(function () {
          window.location.href = that.downAppUrl;
        }, 2000);
      } else {
        window.location.href = that.downAppUrl;
      }
    },
    handleUserResidenceTime() {
      API_Common.delayModel8SUpdate(this.channel, 0, 0, 5);
    },
  },
};
</script>
<style scoped>
@import "../../css/model8Xyb.css"; /*引入公共样式*/
</style>
<style scoped lang="css">
.page {
  background-color: #f4f5f6;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.imageBg {
  padding-top: 0.3rem;
  background-image: url("../../assets/images/model8/bg.png");
  border-radius: 0.02rem;
  background-size: 100%, 100%;
  background-repeat: no-repeat;
}
.proName {
  height: 25px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 25px;
}
.auditSubtitle {
  font-size: 14px;
  font-family: PingFangSC;
  line-height: 14px;
  color: #ffffff;
  text-align: center;
  margin-top: 13px;
  margin-bottom: 20px;
}
.box_xyb {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 100;
}

.group_xyb_1 {
  margin: 100px 20px;
}
.section_xyb_3 {
  padding: 21px 14px 28px;
  background-image: url(../../assets/images/stagingAssessment/xyb_bg.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-y-18 > *:not(:first-child) {
  margin-top: 18px;
}
.image_xyb {
  width: 48px;
  height: 48px;
}
.font_xyb_1 {
  font-size: 16px;
  font-family: PingFangSC;
  line-height: 15px;
  color: #000000;
}
.space-y-22 > *:not(:first-child) {
  margin-top: 22px;
}
.section_xyb_4 {
  padding: 14px 0 3px;
  border-radius: 5px;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16788622859882333668.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.space-x-18 > *:not(:first-child) {
  margin-left: 18px;
}
.equal-division-item {
  flex: 1 1 90px;
  padding: 6px 4px;
}
.space-y-12 > *:not(:first-child) {
  margin-top: 12px;
}
.space-y-6 > *:not(:first-child) {
  margin-top: 6px;
}
.font_xyb_2 {
  font-size: 18px;
  font-family: PingFangSC;
  line-height: 13px;
  color: #000000;
}

.text_xyb {
  color: #f73326;
  font-weight: 600;
}
.text_10 {
  color: #ffffff;
  line-height: 11.5px;
}
.font_xyb_3 {
  font-size: 12px;
  font-family: PingFangSC;
  line-height: 13px;
  color: #999999;
}
.font_xyb_4 {
  font-size: 12px;
  font-family: PingFangSC;
  line-height: 10.5px;
  color: #999999;
}
.text_xyb_2 {
  line-height: 11px;
}
.group_xyb {
  padding: 0px 0 0px;
}
.space-x-8 > *:not(:first-child) {
  margin-left: 8px;
}
.text-wrapper_10 {
  width: 252px;
  height: 17px;
  overflow-wrap: break-word;
  font-size: 0;
  font-family: PingFangSC-Regular;
  text-align: left;
  white-space: nowrap;
  line-height: 17px;
  margin-top: 8px;
}
.text_xyb_3 {
  font-size: 11px;
}
.text_xyb_4 {
  color: #437eff;
  font-size: 11px;
}
.button {
  padding: 17px 0 17px;
  background-image: url("https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/6389b3585a7e3f0310f214a0/638c40610fac6f00123c10ec/16788622859911906013.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  margin-top: 15px;
}
.item_button {
  padding: 10px 0 10px;
  background-color: blue;
  border-radius: 18px;
  width: 2rem;
}
.item_font {
  font-size: 12px;
  font-family: PingFangSC;
  line-height: 12px;
  color: #000000;
}
.item_bg {
  padding: 0px 0px 0px 6px;
}
.item_title {
  font-size: 14px;
  font-family: PingFangSC;
  line-height: 18px;
  color: #666666;
  height: 0.6rem;
  margin-left: 0.1rem;
}

.space-y-8 > *:not(:first-child) {
  margin-top: 8px;
}
.text_xyb_5 {
  color: #ffffff;
  line-height: 16.5px;
}
.text_xyb_6 {
  color: #ffffff;
  opacity: 0.5;
}
.text_xyb_7 {
  margin-top: 26px;
  line-height: 14.5px;
}
.group_xyb_3 {
  margin-top: 24px;
  width: 300px;
  text-align: center;
}
.font_xyb_5 {
  font-size: 10px;
  font-family: PingFangSC;
  line-height: 13px;
  color: #c2c2c2;
  margin: auto;
}
.text_xyb_8 {
  line-height: 12px;
  margin: auto;
}
.text_xyb_9 {
  line-height: 12px;
  margin: auto;
  margin: auto;
}
.pos_xyb {
  position: absolute;
  right: 0;
  bottom: 0;
}
.model8_font_1 {
  font-size: 16px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.bank_bg {
  margin-top: 20px;
  height: 46px;
  background-color: #fff;
  border-radius: 8px;
}
.bankTitle {
  margin-top: 16px;
  margin-left: 12px;
}
.bank_img {
  margin-left: 2px;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-top: 10px;
}
.bank_arrow {
  margin-left: 9px;
  margin-top: 16px;
  color: #999999;
  font-size: 20px;
  font-family: PingFangSC;
  line-height: 2.5px;
  margin-right: 17px;
}
.downloadText {
  color: #000000;
  align-self: center;
  margin-top: 16px;
  font-size: 16px;
}
.colon {
  display: inline-block;
  margin: 0 4px;
  color: #ffffff;
}
.block {
  display: inline-block;
  width: 22px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  background-color: #0066db;
  border-radius: 4px;
}
.time_block {
  background-color: red;
}
.header {
  width: 4.72rem;
  height: 0.42rem;
}
.proTitle {
  width: 1rem;
  height: 0.4rem;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.28rem;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 0.4rem;
}
.proImge {
  width: 25px;
  height: 25px;
  border-radius: 3px;
}
.timeImage {
  width: 1.04rem;
  height: 0.32rem;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.24rem;
  font-family: YouSheBiaoTiHei;
  text-align: center;
  white-space: nowrap;
  line-height: 0.32rem;
  margin-top: 0.04rem;
}
.timeTitle {
  width: 48px;
  height: 20px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  text-align: left;
  white-space: nowrap;
  line-height: 20px;
}
.timebg {
  width: 52px;
  height: 20px;
  margin-left: 0.8rem;
  background-image: url("../../assets/images/model8/model8_apply.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.po_page {
  background-color: rgba(244, 245, 246, 1);
  position: relative;
  width: 375px;
  height: 812px;
  overflow: hidden;
}

.po_section_1 {
  height: 100%;
  /*background: url(./img/SketchPng27e410896d8c015f7b5e268ad3e400b3716f1926b557ae89f1e5cfb61f6d3adf.po_png)*/
  /*  100% no-repeat;*/
  background-size: 100% 100%;
  /*background-color: rgba(0, 0, 0, 0.3);*/
  width: 100%;
}

.po_block_1 {
  background-color: rgba(0, 0, 0, 0.3);
  height: 100%;
  width: 100%;
}

.po_box_1 {
  width: 335px;
  height: 424px;
  background-color: #ffffff;
  border-radius: 5px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.po_thumbnail_1 {
  width: 9px;
  height: 9px;
  margin: 15px 0 0 310px;
}

.po_text-group_1 {
  width: 307px;
  margin-left: 14px;
}

.po_text_1 {
  width: 105px;
  height: 21px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 15px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  line-height: 21px;
  margin-left: 102px;
  margin-top: 8px;
}
.po_content {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  height: 290px;
  font-size: 0px;
  overflow-y: auto;
}
.po_paragraph_1 {
  width: 307px;
  height: 290px;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  text-align: justify;
  line-height: 20px;
  margin-top: 10px;
}

.po_button_1 {
  background-color: rgba(29, 82, 255, 1);
  border-radius: 20px;
  height: 40px;
  width: 263px;
  margin: 19px 0 20px 36px;
}
.contentText {
  font-family: PingFangSC-Regular, PingFang SC;
  font-size: 13px;
  color: #000000;
  line-height: 20px;
}
.po_text_2 {
  width: 144px;
  height: 22px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  text-align: left;
  white-space: nowrap;
  line-height: 22px;
  margin: 9px 0 0 60px;
}
.icon-download {
  width: 6.8rem;
  height: 7.3rem;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.product-item {
  border-radius: 0.16rem;
  padding: 0rem 0rem 0rem 0rem;
  margin-bottom: 0.24rem;
}
</style>
